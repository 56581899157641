import React from "react";
import { graphql } from "gatsby";
import Navbar from "../components/navbar";
import styled from "@emotion/styled";
import Footer from "../components/footer";
import {
  Jumbotron,
  JumbotronH1,
  JumbotronH2,
  JumbotronContent,
} from "../components/jumbotron";
import { Main } from "../components/main";
import SEO from "../components/seo";

const ItemImg = styled.div`
  background-image: url(${(props: any) => props.image});
  background-position: center;
  background-size: cover;
  width: 80px;
  height: 80px;
  flex: 0 0 auto;
  margin-top: 4px;
`;

const ItemTitle = styled.a`
  text-decoration: underline;
`;

function Item({ c }) {
  return (
    <div key={c.id} className="d-flex">
      <ItemImg
        image={
          (c.frontmatter.banner &&
            c.frontmatter.banner.childImageSharp &&
            c.frontmatter.banner.childImageSharp.fluid.src) ||
          ""
        }
      />
      <div className="pl-3">
        <ItemTitle className="h4" href={c.frontmatter.path}>
          {c.frontmatter.title}
        </ItemTitle>
        <p>{c.frontmatter.description || c.excerpt}</p>
      </div>
    </div>
  );
}

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark, allMarkdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;
  const [modal, setModal] = React.useState(false);
  const path = markdownRemark.fileAbsolutePath
    .split("/")
    .slice(0, -1)
    .join("/");
  const children = allMarkdownRemark.edges
    .map(e => e.node)
    .filter(
      n => n.fileAbsolutePath.indexOf(path) == 0 && n.id != markdownRemark.id
    );
  const half = Math.round(children.length / 2);
  return (
    <>
      <SEO
        lang="fr"
        description={frontmatter.description}
        title={frontmatter.title}
        meta={[{ name: "keywords", content: frontmatter.keywords }]}
      />
      <Navbar />
      <div className="container">
        {/* JUMBOTRON */}
        <Jumbotron
          image={
            (frontmatter.banner &&
              frontmatter.banner.childImageSharp.fluid.src) ||
            ""
          }
        >
          <JumbotronContent>
            <div>
              <JumbotronH1>
                Assurance décennale
                <JumbotronH2>{frontmatter.subtitle}</JumbotronH2>
              </JumbotronH1>
            </div>
          </JumbotronContent>
        </Jumbotron>
        {/* CONTENT */}
        <Main>
          <div className="mb-4" dangerouslySetInnerHTML={{ __html: html }} />
          <div className="row">
            <div className="col-md-6">
              {children.slice(0, half).map(c => (
                <Item c={c} key={c.id} />
              ))}
            </div>
            <div className="col-md-6">
              {children.slice(half).map(c => (
                <Item c={c} key={c.id} />
              ))}
            </div>
          </div>
        </Main>
      </div>
      <Footer />
    </>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      id
      fileAbsolutePath
      html
      frontmatter {
        path
        title
        subtitle
        category
        keywords
        description
        banner {
          childImageSharp {
            fluid(maxWidth: 1100, maxHeight: 400) {
              src
            }
          }
        }
      }
    }
    allMarkdownRemark {
      edges {
        node {
          id
          fileAbsolutePath
          excerpt(format: PLAIN)
          frontmatter {
            path
            title
            category
            template
            description
            banner {
              childImageSharp {
                fluid(maxWidth: 200, maxHeight: 200) {
                  src
                }
              }
            }
          }
          tableOfContents
        }
      }
    }
  }
`;
